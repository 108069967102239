import React, { Fragment } from 'react';
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import ThreeDRotation from '@material-ui/icons/ThreeDRotation'
// import Tooltip from '@material-ui/core/Tooltip';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import HomeIcon from '@material-ui/icons/Home';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DrawerLayout from '../components/drawerLayout';
import ImageSlider from "../components/imageSlider";
// import RubleIcon from "../components/rubleIcon";
// import RubleEmptyIcon from "../components/rubleEmptyIcon";
// import RatingEx from "../components/ratingEx";
import { useCardStyles, useRatingStyles, useMenuStyles } from '../components/styles';
import { makeStyles } from '@material-ui/core/styles';
import { YaAdvBox } from '../components/YaAdvBox';
import TileImage from '../components/tileImage';

// https://stackoverflow.com/questions/4086107/fixed-page-header-overlaps-in-page-anchors
// https://stackoverflow.com/questions/58239215/how-to-simulate-material-uis-theme-mixins-toolbar-using-sass
const useStyles = makeStyles(theme => ({
  scrollOffsetFix: {
    height: 56 + 8,
    marginTop: -(56 + 8),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48 + 8,
      marginTop: -(48 + 8),
    },
    [theme.breakpoints.up('sm')]: {
      height: 64 + 8,
      marginTop: -(64 + 8),
    },
  },
}));


function reduceDesignsByTemplate(designs) {
  const designsMap = new Map();
  const regex = / №.+$/gm;
  for(let design of designs) {
    const templateName = design.name.replace(regex, '');
    if(!designsMap.has(templateName)) {
      designsMap.set(templateName, []);
    }
    designsMap.get(templateName).push(design);
  }
  return Array.from(designsMap);
}

function CollectionPageTemplate(props) {
  const { container, data, pageContext } = props;
  const brand = data.brandsJson;
  const collectionName = pageContext.collection;
  const cardClasses = useCardStyles();
  const ratingClassess = useRatingStyles();
  const menuClasses = useMenuStyles();
  const dddBaseUrl = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';
  const designsByTemplate = reduceDesignsByTemplate(data.allDesignsJson.nodes);
  const regexRemoveSpace = /№\s*([0-9]+)$/gm;
  const substRemoveSpace = `№$1`;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const designsCount = data.allDesignsJson.nodes.length;
  const advStartIndex = 0;
  const advRepeat = 8;
  const advCount = Math.ceil((designsCount - advStartIndex) / advRepeat);
  const totalWithAdv = designsCount + advCount;

  return (
    <DrawerLayout
      mobileOpen={mobileOpen} onDrawerToggle={() => setMobileOpen(!mobileOpen)}
      container={container}
      title={collectionName + ' (' + brand.fullName + ') фото интерьеров ванных комнат'}
      menu={
        <List dense={true}>
          <ListItem button component={Link} to="/">
            <ListItemAvatar>
              <Avatar aria-label="на главную страницу">
                <HomeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ПлиткаБот" secondary={data.allBrandsJson.totalCount + ' брендов'} />
          </ListItem>
          <ListItem button component={Link} to={'/' + brand.name + '/'}>
            <ListItemAvatar>
              <Avatar aria-label={brand.fullName} style={{ backgroundColor: brand.color }}>
                {brand.name[0].toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={brand.fullName} secondary={brand.collectionsCount + ' коллекций'} />
          </ListItem>
          <ListItem button selected className={menuClasses.nested1}>
            <ListItemAvatar>
              <Avatar><PhotoLibraryIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText primary={collectionName} secondary={data.allDesignsJson.nodes.length + ((data.allDesignsJson.nodes.length < 5) ? ' дизайна' : ' дизайнов')} />
          </ListItem>
          {designsByTemplate.map(([templateName, designs]) => (
            <ListItem button component={Link} to={ '#design' + designs[0].id } onClick={(e) => {setMobileOpen(false);}} key={designs[0].id} className={menuClasses.nested2}>
              <ListItemText primary={templateName}></ListItemText>
              <Chip size="small" label={designs.length} />
            </ListItem>
          ))}
        </List>
      }
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={ `Варианты раскладки плитки ${collectionName}, ${brand.fullName}, ${data.allDesignsJson.nodes.length} фото классических и современных интерьеров ванных комнат` } />
        <title>Раскладка плитки {collectionName + ', ' + brand.fullName}, {'' + data.allDesignsJson.nodes.length} фото интерьеров ванных комнат</title>
        <script src="https://yandex.ru/ads/system/context.js" async></script>
      </Helmet>
      <Box m={0} p={0} display="flex" flexWrap="wrap" justifyContent="space-between">
        { data.allDesignsJson.nodes.map((design, index) => (
          <Fragment key={index}>
            { (index % advRepeat) === advStartIndex ? ( <YaAdvBox advId={index} /> ) : (null) }
            <Card className={cardClasses.card} key={design.id}>
              {/* <div id={ 'design' + design.id } style={{ height: '78px', margin: '-78px 0 0' }}></div> */}
              <div id={ 'design' + design.id } className={classes.scrollOffsetFix}></div>
              <CardHeader
                avatar={
                  <Avatar aria-label={brand.fullName} style={{ backgroundColor: brand.color }}>
                    {brand.name[0].toUpperCase()}
                  </Avatar>
                }
                // action={
                  // <Tooltip title="Посмотреть в 3D" placement="top" arrow>
                  //   <IconButton aria-label="3D" href={ dddBaseUrl + '/app/?b=' + brand.name + '&c=' + collectionName + '&d=' + design.id} color="secondary">
                  //     <ThreeDRotation />
                  //   </IconButton>
                  // </Tooltip>
                // }
                title={collectionName}
                subheader={design.name.replace(regexRemoveSpace, substRemoveSpace)}
              />
              <CardMedia>
                <TileImage
                  src={ design.images.map((filename) => ('/images/' + brand.name + '/' + filename)) }
                  alt={ brand.fullName + ', ' + collectionName + ', ' + design.name }
                />
                {/* <ImageSlider
                  src={ design.images.map((filename) => ('/images/' + brand.name + '/' + filename)) }
                  alt={ brand.fullName + ', ' + collectionName + ', ' + design.name }
                /> */}
              </CardMedia>
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  href={ dddBaseUrl + '/app/?b=' + brand.name + '&c=' + collectionName + '&d=' + design.id}
                >Посмотреть в 3D</Button>
              </CardActions>
              {/* <CardActions disableSpacing>
                <RatingEx
                  classes={ratingClassess}
                  value={design.priceLevel}
                  readOnly
                  precision={0.5}
                  icon={<RubleIcon fontSize="inherit" />}
                  emptyIcon={<RubleEmptyIcon fontSize="inherit" />}
                  size="small"
                />
              </CardActions> */}
            </Card>
          </Fragment>
        ))
      }
      {
        Array(3 - totalWithAdv % 3).fill().map((item, index) => (
          <Box ml={1} mr={1} style={{flex: "1 1 30%"}} minWidth="300px" height={0} key={'extra-' + index}></Box>
        ))
      }
      </Box>
    </DrawerLayout>
  );
}

export default CollectionPageTemplate;

export const query = graphql`
  query ($brand: String!, $collection: String!) {
    brandsJson(name: {eq: $brand}) {
      name
      fullName
      color
      collectionsCount
    }
    allDesignsJson(filter: {brand: {eq: $brand}, collection: {eq: $collection}}) {
      nodes {
        brand
        collection
        id
        name
        images
      }
    }
    allRatingsJson(filter: {brand: {eq: $brand}, collection: {eq: $collection}}) {
      edges {
        node {
          brand
          collection
          id
          value
        }
      }
    }
    allBrandsJson {
      totalCount
    }
  }
`
